<!--
 * @Author: kevin
 * @Date: 2021-5-19 09:09:45
 * @LastEditTime: 2021-02-04 11:18:37
-->

<template>
  <div class="components-list">
    <div class="select-tag">
      <span :class="{ tagActive: tabPosition == 'first' }" @click="changeTag('first')">首页</span>
      <span :class="{ tagActive: tabPosition == 'second' }" @click="changeTag('second')">发现</span>
      <span :class="{ tagActive: tabPosition == 'thrid' }" @click="changeTag('thrid')">微页面</span>
    </div>
    <div class="cl-content" v-if="tabPosition == 'first'">
      <p>基础组件</p>
      <el-row :gutter="10">
        <template v-for="item in headerData">
          <el-col :span="8" :key="item.type" class="cl-item">
            <div @click="addCompt(item.type)">
              <img :src="item.url" :alt="item.title" />
              <p>{{ item.title }}</p>
            </div>
          </el-col>
        </template>
      </el-row>
      <p>商品组件</p>
      <el-row :gutter="10">
        <template v-for="item in waterFallData">
          <el-col :span="8" :key="item.type" class="cl-item">
            <div @click="addCompt(item.type)">
              <img :src="item.url" :alt="item.title" />
              <p>{{ item.title }}</p>
            </div>
          </el-col>
        </template>
      </el-row>

      <p>魔方组件</p>
      <el-row :gutter="10">
        <template v-for="item in cubeData">
          <el-col :span="8" :key="item.type" class="cl-item">
            <div @click="addCompt(item.type)">
              <img :src="item.url" :alt="item.title" />
              <p>{{ item.title }}</p>
            </div>
          </el-col>
        </template>
      </el-row>

      <p>限时秒杀</p>
      <el-row :gutter="10">
        <template v-for="item in seckillData">
          <el-col :span="8" :key="item.type" class="cl-item">
            <div @click="addCompt(item.type)">
              <img :src="item.url" :alt="item.title" />
              <p>{{ item.title }}</p>
            </div>
          </el-col>
        </template>
      </el-row>
    </div>
    <div class="cl-content" v-if="tabPosition == 'second'"></div>
    <div class="cl-content" v-if="tabPosition == 'thrid' && showComponents">
      <p>魔方组件</p>
      <el-row :gutter="10">
        <template v-for="item in tinyCubeData">
          <el-col :span="8" :key="item.type" class="cl-item">
            <div @click="addCompt(item.type)">
              <img :src="item.url" :alt="item.title" width="40" height="40"/>
              <p>{{ item.title }}</p>
            </div>
          </el-col>
        </template>
      </el-row>

      <p>内容组件</p>
      <el-row :gutter="10">
        <template v-for="item in tinyContentData">
          <el-col :span="8" :key="item.type" class="cl-item">
            <div @click="addCompt(item.type)">
              <img :src="item.url" :alt="item.title" width="40" height="40"/>
              <p>{{ item.title }}</p>
            </div>
          </el-col>
        </template>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'components-list',
  props:{
    showComponents:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      tabPosition: 'first',
      headerData: [
        {
          type: 'search',
          title: '定位/搜索框',
          url: require('@/asset/mail/定位、搜索.png')
        },
        {
          type: 'carousel',
          title: '轮播图',
          url: require('@/asset/mail/轮播图.png')
        },
        {
          type: 'searchAndCarousel',
          title: '定位/搜索/轮播图',
          url: require('@/asset/mail/定位、搜索、轮播图.png')
        },
        {
          type: 'search2', //待加, 先mock
          title: '搜索/轮播图',
          url: require('@/asset/mail/搜索、轮播.png')
        },
        {
          type: 'classify', //待加, 先mock
          title: '金刚区',
          url: require('@/asset/mail/金刚区.png')
        },
        {
          type: 'areaAndClassify', //待加, 先mock
          title: '分类/商圈导航',
          url: require('@/asset/mail/分类商圈.png')
        },
        {
          type: 'homePageDialog',
          title: '首页弹窗',
          url: require('@/asset/mail/首页弹框.png')
        }
      ],
      cubeData: [
        {
          type: 'cube_1',
          title: '左2右2',
          url: require('@/asset/mail/魔方组件1.png')
        },
        {
          type: 'cube_2',
          title: '上1下2',
          url: require('@/asset/mail/魔方组件2.png')
        },
        {
          type: 'cube_3',
          title: '左1（轮播）右2',
          url: require('@/asset/mail/魔方组件3.png')
        },
        {
          type: 'cube_4',
          title: '一行三个',
          url: require('@/asset/mail/魔方组件4.png')
        },
        {
          type: 'cube_5',
          title: '一行两个',
          url: require('@/asset/mail/魔方组件5.png')
        }
      ],
      waterFallData: [
        {
          type: 'waterfall_1',
          title: '横向列表',
          url: require('@/asset/mail/商品组件3.png')
        },
        {
          type: 'waterfall_2',
          title: '纵向两列',
          url: require('@/asset/mail/商品组件2.png')
        },
        {
          type: 'waterfall_3',
          title: '瀑布流',
          url: require('@/asset/mail/商品组件1.png')
        }
      ],
      seckillData: [
        {
          type: 'seckill',
          title: '秒杀商品',
          url: require('@/asset/mail/并列秒杀商品.png')
        }
      ],
      tinyContentData:[
        {
          type: 'textContent',
          title: '文本组件',
          url: require('@/asset/mail/文本组件.png')
        },
        {
          type: 'imageUrl',
          title: '图片组件',
          url: require('@/asset/mail/图片组件.png')
        },
        {
          type: 'productListComponent',
          title: '商品列表',
          url: require('@/asset/mail/商品列表.png')
        }
      ],
      tinyCubeData:[
        {
          type: 'cube_1',
          title: '左2右2',
          url: require('@/asset/mail/魔方组件1.png')
        },
        {
          type: 'cube_2',
          title: '上1下2',
          url: require('@/asset/mail/魔方组件2.png')
        },
        {
          type: 'cube_3',
          title: '左1（轮播）右2',
          url: require('@/asset/mail/魔方组件3.png')
        },
        {
          type: 'cube_4',
          title: '一行三个',
          url: require('@/asset/mail/魔方组件4.png')
        },
        {
          type: 'cube_5',
          title: '一行两个',
          url: require('@/asset/mail/魔方组件5.png')
        },
        {
          type: 'cube_6',
          title: '单图无边框',
          url: require('@/asset/mail/魔方组件6.png')
        }
      ]
    }
  },
  methods: {
    addCompt(v) {
      this.$message.success('添加成功')
      this.$emit('add-compt', v)
    },
    changeTag(tabPosition) {
      this.tabPosition = tabPosition
      this.$emit('change-tag', tabPosition)
    }
  }
}
</script>

<style lang="less" scoped>
.tagActive {
  background-color: @color-primary-select !important;
  color: @color-background;
}
.components-list {
  margin-left: 10px;
  position: relative;
  padding: 30px 10px 30px 20px;
  min-width: 210px;
  cursor: pointer;
  background-color: @color-background;
  .radius(10px);
  .select-tag {
    margin-bottom: 20px;
    font-size: 14px;

    span {
      display: inline-block;
      height: 28px;
      line-height: 28px;
      text-align: center;
      margin-right: 10px;
      margin-top: 10px;
      padding: 0 15px;
      .radius(14px);
      &:hover {
        background-color: rgba(231, 20, 20, 0.2);
      }
    }
  }
  .cl-content {
    overflow-y: auto;
    max-height: 750px;

    &::-webkit-scrollbar-thumb {
      border-radius: 30px;
      background-color: #f1f3f4;
    }
    &::-webkit-scrollbar-track {
      border-radius: 30px;
      background-color: #ffffff;
    }
    .cl-inner {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    & > p {
      height: 18px;
      font-size: 18px;
      font-weight: 400;
      color: @color-primary;
      margin-bottom: 18px;
    }
    .cl-item {
      & > p {
        height: 15px;
        font-size: 14px;
        font-weight: 400;
        color: @color-primary;
        margin-bottom: 10px;
      }
      & > div {
        margin-bottom: 20px;
        text-align: center;
        p {
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          margin-bottom: 7px;
        }
        img {
          border: 1px dashed #999999;
        }
      }
      .drag_wrapper * {
        pointer-events: none;
      }
    }
  }
}
</style>
